import React, { useState, useRef } from "react"
import { Menu, Transition } from "@headlessui/react"
import Link from "./Link"
import ExternalLink from "../common/ExternalLink"
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick"

export default function MenuItem({ menuItem }) {
  const dropdownRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useDetectOutsideClick(
    dropdownRef,
    false
  )
  const [mouseOverButton, setMouseOverButton] = useState(false)
  const [mouseOverMenu, setMouseOverMenu] = useState(false)
  const timeoutDuration = 200
  let timeoutButton
  let timeoutMenu

  const onMouseEnterButton = () => {
    clearTimeout(timeoutButton)
    setOpenDropdown(true)
    setMouseOverButton(true)
  }
  const onMouseLeaveButton = () => {
    timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
  }

  const onMouseEnterMenu = () => {
    clearTimeout(timeoutMenu)
    setMouseOverMenu(true)
  }
  const onMouseLeaveMenu = () => {
    timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
  }
  const [mobileOpen, setMobileOpen] = useState(false)

  const show =
    (openDropdown && (mouseOverMenu || mouseOverButton)) || mobileOpen
  return (
    <div>
      {menuItem.subMenu === null ? (
        <Link
          className="hover:bg-third py-6 px-4 h-full inline-block"
          to={menuItem.route}
        >
          <div>{menuItem.title}</div>
        </Link>
      ) : (
        <Menu>
          <div
            onTouchStart={() => setMobileOpen(!mobileOpen)}
            // onTouchEnd={() => setOpenDropdown(!openDropdown)}
            onMouseEnter={onMouseEnterButton}
            onMouseLeave={onMouseLeaveButton}
            onKeyPress={null}
            role="button"
            tabIndex="0"
          >
            <Menu.Button as="a">
              <div
                className={`${
                  show ? "bg-third" : ""
                } cursor-pointer py-6 px-4 hover:bg-third `}
              >
                {menuItem.title}
              </div>
            </Menu.Button>
          </div>
          <div className="absolute">
            <Transition show={show}>
              <Menu.Items
                ref={dropdownRef}
                onMouseEnter={onMouseEnterMenu}
                onMouseLeave={onMouseLeaveMenu}
                static
                className=" z-10  px-2 w-screen max-w-xl sm:px-0 "
              >
                <div className="">
                  <div className="relative  bg-third px-4 py-6">
                    <nav className="space-y-6">
                      {menuItem.subMenu?.map(subMenuItem => {
                        return (
                          <Menu.Item
                            key={subMenuItem.route}
                            as="div"
                            onClick={() => setOpenDropdown(false)}
                          >
                            <div className="flex flex-row items-center">
                              <div>
                                <Link
                                  className="-m-3 px-3 "
                                  to={subMenuItem.route}
                                >
                                  {subMenuItem.title}
                                </Link>
                              </div>
                              {subMenuItem.isExternal === true && (
                                <div className=" w-4 ml-4">
                                  <ExternalLink />
                                </div>
                              )}
                            </div>
                          </Menu.Item>
                        )
                      })}
                    </nav>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </Menu>
      )}
    </div>
  )
}
