import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 36.79 36.89"
    >
      <path
        d="M253 260.35a1.28 1.28 0 01-.89-.36 1.25 1.25 0 010-1.77l20.57-20.57a1.25 1.25 0 111.77 1.77L253.9 260a1.24 1.24 0 01-.9.35z"
        className="cls-1"
        transform="translate(-238.05 -237.28)"
      ></path>
      <path
        d="M273.59 239.78h-6.33a1.25 1.25 0 010-2.5h6.33a1.25 1.25 0 010 2.5z"
        className="cls-1"
        transform="translate(-238.05 -237.28)"
      ></path>
      <path
        d="M273.59 246.11a1.25 1.25 0 01-1.25-1.25v-6.33a1.25 1.25 0 012.5 0v6.33a1.25 1.25 0 01-1.25 1.25zM267.41 274.17H239.3a1.25 1.25 0 01-1.25-1.25v-28.2a1.25 1.25 0 011.25-1.25h20.91a1.25 1.25 0 010 2.5h-19.66v25.7h25.61V252a1.25 1.25 0 012.5 0v21a1.25 1.25 0 01-1.25 1.17z"
        className="cls-1"
        transform="translate(-238.05 -237.28)"
      ></path>
    </svg>
  )
}

export default Icon
