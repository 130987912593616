import React from "react"

export default function BcLogo() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 21.7 56.78"
        fill="#303030"
      >
        <g data-name="Group 201">
          <g data-name="Group 201-2">
            <path
              d="M245.27 274.29c0 6.12 4.26 10.23 10.59 10.23h9.3v-4.58h-9.3c-3.76 0-6-2.11-6-5.65 0-2.73 1.58-5.64 6-5.64h9.3v-4.58h-9.3c-6.23 0-10.59 4.2-10.59 10.22"
              className="cls-1"
              data-name="Path 424"
              transform="translate(-245.27 -227.74)"
            ></path>
          </g>
          <g data-name="Group 204">
            <path
              d="M267 249.27c0-6.12-4.25-10.23-10.59-10.23h-4.72v-11.3h-4.57v31.75h9.3c6.23 0 10.59-4.2 10.59-10.23m-15.32-5.64h4.73c3.76 0 6 2.11 6 5.65 0 2.73-1.58 5.64-6 5.64h-4.73z"
              className="cls-1"
              data-name="Path 427"
              transform="translate(-245.27 -227.74)"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  )
}
