/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./common/Footer"
import HeaderEn from "./common/HeaderEn"
import HeaderSe from "./common/HeaderSe"

const Layout = ({ children, isHome, lang, translation }) => {
  const [agreed, setAgreed] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("agree")) {
      setAgreed(true)
    }
  }, [])

  const handleClick = () => {
    localStorage.setItem("agree", "true")
    setAgreed(true)
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="text-fourth text-lg lg:text-base">
      {isHome ? (
        <div className="flex flex-col bg-fifth  overflow-hidden">
          <div className="flex flex-col h-screen relative">
            <div className="z-40">
              {lang === "en" ? (
                <HeaderEn
                  lang={lang}
                  siteTitle={data.site.siteMetadata?.title || `Title`}
                  translation={translation}
                />
              ) : (
                <HeaderSe
                  lang={lang}
                  siteTitle={data.site.siteMetadata?.title || `Title`}
                  translation={translation}
                />
              )}
            </div>
            <div className="flex-1 overflow-hidden bg-red-20 z-10">
              {children}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col min-h-screen bg-fifth  overflow-hidden">
          {lang === "en" ? (
            <HeaderEn
              lang={lang}
              siteTitle={data.site.siteMetadata?.title || `Title`}
              translation={translation}
            />
          ) : (
            <HeaderSe
              lang={lang}
              siteTitle={data.site.siteMetadata?.title || `Title`}
              translation={translation}
            />
          )}

          <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8  ">
            {children}
          </main>
          <Footer />
        </div>
      )}

      {!agreed && (
        <div className="m-4 lg:m-0 lg:w-full fixed left-0 bottom-0 bg-white rounded-md z-50">
          <div
            id="cookie-consent"
            className="py-4 px-4 max-w-4xl w-full mx-auto flex items-center flex-col lg:flex-row justify-between space-y-4 lg:space-y-0"
          >
            <div className="text-center lg:text-left">
              Bodecker Carbon is using cookies to improve your experience on our
              site.
            </div>
            <div className="space-x-4">
              <button onClick={handleClick}>I Accept</button>
              <button>Learn More</button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
