export const MenuEn = {
  name: "Main Menu",
  items: [
    {
      title: "Services",
      route: null,
      subMenu: [
        {
          title: "Analysis & Forecasts",
          route: "/en/services/analysis-forecasts/",
          isExternal: false,
        },
        {
          title: "Portfolio Management & Advisory",
          route: "/en/services/portfolio-management-advisory",
        },
        {
          title: "Bodecker Partners: Nordic Power market expertise",
          route: "https://www.bodeckerpartners.com/en/",
          isExternal: true,
        },
      ],
    },
    {
      title: "Reports",
      route: "/en/reports/",
      subMenu: null,
    },
    {
      title: "About Us",
      route: "/en/about-us",
      subMenu: null,
    },
  ],
}
