export const MenuSe = {
  name: "Main Menu",
  items: [
    {
      title: "Tjänster",
      route: null,
      subMenu: [
        {
          title: "Analys och Prognos",
          route: "/tjanster/analys-prognos/",
          isExternal: false,
        },
        {
          title: "Portföljförvaltning & rådgivning",
          route: "/tjanster/portfoljforvaltning-radgivning/",
        },
        {
          title: "Bodecker Partners: Nordisk kraftmarknadsexpertis",
          route: "https://www.bodeckerpartners.com/",
          isExternal: true,
        },
      ],
    },
    {
      title: "Rapporter",
      route: "/rapporter/",
      subMenu: null,
    },
    {
      title: "Om oss",
      route: "/om-oss/",
      subMenu: null,
    },
  ],
}
