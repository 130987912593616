import React from "react"

export default function Footer() {
  return (
    <div className="bg-seventh text-fourth text-xs px-4 pt-4 pb-8 lg:p-8">
      <div className="max-w-4xl mx-auto grid md:grid-cols-2 gap-4 w-full  lg:px-8">
        <div className="">
          <div className="font-bold">
            ​Bodecker Carbon –en del av Bodecker Partners
          </div>
          <div>
            Vi har en unik expertis och erfarenhet av EU ETS och handel med
            utsläppsrätter. Vi har följt marknaden som analytiker, riskmanagers
            och finansiell handlare sedan start av EU ETS, och hanterat
            portföljer för både energibolag och industrier.
          </div>
        </div>
        <div className="space-y-2 md:pl-2">
          <div>
            <div className="font-bold">Contact Us</div>
            <div>
              Mail: info@bodeckercarbon.com
              <br />
              Tel: +46 732 55 87 27
            </div>
          </div>
          <div>
            <div className="font-bold">Adress</div>
            <div>
              Bodecker Carbon
              <br />
              C/O Bodecker Partners
              <br />
              Lilla Varvsgatan 51b
              <br />
              211 74 Malmö{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
